<template>
  <el-row v-if="editable">
    <el-image v-if="url && !isUpdated" style="width: 146px; height: 146px;margin-right: 5px" :src="url"
      :preview-src-list="[url]"></el-image>
    <el-upload ref="uploadRef" list-type="picture-card" accept=".jpeg,.png,.jpg" :file-list="imgVal" :action="action"
      :limit="limit" :on-preview="file => handlePictureCardPreview(file)"
      :on-remove="response => emit('handleRemove', response)" :on-success="response => emit('handleSuccess', response)"
      :before-upload="beforeImgUpload" :headers="headerObj(authorizationId)" :on-error="handleError">
      <img src="@/assets/images/upload.svg" alt="" />
      <div class="el-upload__text">
        <span class="LinkText">{{ uploadText }}</span><br />
        {{ uploadInfo }}
      </div>
    </el-upload>
    <el-dialog v-model="imgVisible">
      <img w-full :src="imgUrl" alt="" />
    </el-dialog>
    <el-space direction="vertical" style="margin-left: 40px;justify-content: end;" v-if="showExample" :size="4">
      <el-image style="width: 175px; height: 140px" :src="showImgSrc" :preview-src-list="showImg" />
      <span class="LinkText" :span="24" v-if="editable">（点击上方查看示例图）</span>
    </el-space>
  </el-row>
  <el-row v-else>
    <el-image style="width: 270px; height: 180px;" :src="showImgSrc" :preview-src-list="showImg" :initial-index="1" />
  </el-row>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { computed, getCurrentInstance, ref } from 'vue'

const { proxy } = getCurrentInstance()
const props = defineProps({
  action: { required: true },
  editable: { required: true },
  url: { required: true },
  authorizationId: { required: true },
  limit: { default: 1 },
  uploadText: { default: '' },
  uploadInfo: { default: '支持jpeg、png、jpg格式' },
  showExample: { default: false },
  previewSrcList: { default: '' },
  span: { default: 12 }
})

const uploadRef = ref(null)
const emit = defineEmits(['handleRemove', 'handleSuccess'])
const imgVal = ref([])
const imgUrl = ref(props.url)
const imgVisible = ref(false)
const isUpdated = ref(false)

const showImg = computed(() => {
  if (!props.previewSrcList) return [imgVal.value]
  if (typeof props.previewSrcList === 'string') return [props.previewSrcList]
  return props.previewSrcList
})

const showImgSrc = computed(() => {
  if (!props.previewSrcList) return imgVal.value
  if (typeof props.previewSrcList === 'string') return props.previewSrcList
  return props.previewSrcList.length > 0 ? props.previewSrcList[0] : ''
})

const beforeImgUpload = file => {
  isUpdated.value = true
  let img = file.name.substring(file.name.lastIndexOf('.') + 1)

  const isImg = ['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG'].indexOf(img) !== -1
  if (['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG'].indexOf(img) < 0) {
    ElMessage.error('仅支持上传图片！')
  }

  const isLt10M = file.size / 1024 / 1024 < 10
  if (!isLt10M) {
    ElMessage.error(`${file.name}大小不符合，不能超过 10MB!`)
  }

  return isImg && isLt10M
}

const headerObj = id => {
  return { Authorization: proxy.$store.getters.userInfo.token + (id > 0 ? `,${id}` : '') }
}

// 处理图片预览功能
const handlePictureCardPreview = file => {
  imgUrl.value = file.response && file.response.path ? file.response.path : ''
  imgVisible.value = true
}

const handleError = params => {
  console.log(params)
}

const refreshData = () => {
  isUpdated.value = false
  if (uploadRef.value) uploadRef.value.clearFiles()
}

defineExpose({ refreshData })
</script>

<style lang="less" scoped>
:deep(.el-upload),
:deep(.el-upload-dragger) {
  min-width: 270px !important;
  min-height: 180px !important;

  .el-upload__text {
    position: relative;
    bottom: 60px;
    line-height: 1.5;
    color: #595959;
  }
}

:deep(.el-upload--picture-card i) {
  font-size: 67px;
}

.LinkText {
  color: #2b82ff;
  cursor: pointer;
}
</style>
