<template>
  <!-- 企业认证 -->
  <el-card class="box-card" style="min-height: 800px">
    <template #header>
      <div class="card-header">
        <span class="SubHead">企业认证</span>
        <!-- <el-link :underline="false"> -->
        <!-- <span class="LinkText" @click="unbindCompany" style="margin-left: 10px" -->
        <!-- v-if="company.status == 1"> 解绑企业</span> -->
        <!-- </el-link> -->
      </div>
    </template>
    <el-row :gutter="20">
      <el-col :span="16">
        <client-form v-if="company.status !== undefined" :company="company" :userinfo="getUserInfo"></client-form>
      </el-col>
      <el-col :span="8" style="display: flex;justify-content: center;align-items: center;">
        <img src="@/assets/bg_2-2.png" alt="" style="max-width: 420px;width: 100%;opacity: 0.5;margin-right: 40px;" />
      </el-col>
    </el-row>
  </el-card>

  <email-bind v-if="dialogType === 'email-bind'"></email-bind>
</template>

<script setup>
import ClientForm from "./client/ClientForm";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import EmailBind from "@/views/userinfo/approve/client/EmailBind";


const { proxy } = getCurrentInstance()
const company = ref({})
const dialogType = ref('')

const getUserInfo = (key = '') => {
  const info = proxy.$store.getters.userInfo
  if (key) return info[key] ? info[key] : ''
  return info
}

const unbindCompany = async () => {
  const confirmResult = await ElMessageBox.confirm(`您确定要解绑企业吗？解绑后需重新进行企业认证`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).catch(err => err)

  if (confirmResult !== 'confirm') {
    return
  } else {
    const { data: res } = await proxy.$http.post('cloudfb/cancel_attest', {
      type: 1
    })
    if (res.code == 100) {
      ElMessage.error(`${res.msg}`)
      proxy.$router.push('/clientInfo')
    }
    if (res.code == 300) {
      proxy.$router.push('/login')
    }
    ElMessage.success('解绑成功')
    window.location.reload()
  }
}

onMounted(async () => {
  if (!getUserInfo('phone')) {
    ElMessage.warning('您还未设置手机号，请设置后再进行认证')
    proxy.$router.push('/users')
    return
  }

  const { data: res } = await proxy.$http.post('cloudfb/company_attest', {
    token: getUserInfo('token')
  })

  if (res.code == 300) {
    proxy.$router.push('/login')
    return
  }


  if (res.data && [1, 2].indexOf(res.data.status) < 0 && getUserInfo('is_email') !== 1 && !localStorage.getItem('isGuideWrapNewShow')) {
    dialogType.value = 'email-bind'
  }

  company.value = Object.assign({ status: res.status ? res.status : 0 }, res.data ? res.data : {})
})

</script>

