<template>
  <el-dialog v-model="visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false"
    title="绑定邮箱" width="600px">
    <p>
      企业认证前，请绑定密保邮箱，及时获取认证动态和使用说明
    </p>
    <el-form ref="formRef" :rules="rules" :model="form">
      <el-form-item prop="email">
        <el-input v-model="form.email" size="small" placeholder="请输入邮箱号" clearable></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-input v-model="form.code" placeholder="请输入邮箱验证码" size="small" clearable></el-input>
          </el-col>
          <el-col :span="6" style="text-align:right;">
            <el-button type="primary" ref="editPhoneRef" size="small" :disabled="!checkEmail || countDownTime > 0"
              @click="getCode">
              {{ countDownTime > 0 ? `已发送 ${countDownTime}s` : '发送邮箱验证码' }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from "vue";
import { ElMessage } from "element-plus";

const form = ref({
  email: '',
  code: ''
})

const rules = ref({
  email: [
    { required: true, message: '请输入邮箱号码', trigger: 'blur' },
    {
      pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
      message: '请输入正确格式的邮箱号码',
      trigger: 'change'
    }
  ],
  code: [{ required: true, message: '请输入正确的邮箱验证码', trigger: 'blur' }]
})
const formRef = ref()

const visible = ref(true)
const countDownTime = ref(0)
const countDown = ref(null)

const { proxy } = getCurrentInstance()

const checkEmail = computed(() => {
  return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(form.value.email)
})

const getCode = async () => {
  // 如果当前没有计时
  if (!countDownTime.value) {
    // 启动倒计时
    countDownTime.value = 60
    countDown.value = window.setInterval(() => {
      countDownTime.value--
      if (countDownTime.value <= 0) {
        clearInterval(countDown.value)
      }
    }, 1000)
    const { data: res } = await proxy.$http.post('user/edit_email', {
      email: form.value.email,
      type: 1
    })
    if (res.code == 100) return ElMessage.error(`${res.msg}`)
    if (res.code == 200) return ElMessage.success(`${res.msg}`)
  }
}

const submit = () => {
  formRef.value.validate(async valid => {
    if (!valid) return
    const { data: res } = await proxy.$http.post('user/edit_email', {
      email: form.value.email,
      code: form.value.code,
      type: 2
    })
    if (res.code == 100) return ElMessage.error(`${res.msg}`)
    if (res.code == 300) {
      proxy.$router.push('/login')
    }
    ElMessage.success('绑定成功')
    window.localStorage.setItem('userInfo', JSON.stringify(res.user))
    proxy.$store.dispatch("EDIT_USERINFO", res.user)
    visible.value = false
    window.location.reload()
  })
}

</script>

<style scoped></style>